import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import Api from "../../../../helpers/Api";
import { useDispatch } from "react-redux";
const EditBlockDrawer = ({ open, setOpen, block, onSuccess }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    if (block) {
      setFormData({
        name: block.name || "",
        description: block.description || "",
      });
    }
  }, [block]);

  const handleClose = () => {
    setOpen(false);
    setFormData({
      name: "",
      description: "",
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await Api.post("/public/project/block/update", {
        blockId: block?._id,
        name: formData?.name,
        description: formData?.description,
      });

      if (response.data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Block updated successfully",
          },
        });
        await onSuccess?.();
        handleClose();
      }
    } catch (error) {
      console.error("Error updating block:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error updating block",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { width: { xs: "100%", sm: 400 } },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h6">Edit Block</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Block Name"
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />

          <TextField
            label="Description"
            fullWidth
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />

          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <Button
              variant="outlined"
              onClick={handleClose}
              fullWidth
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Save Changes"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default EditBlockDrawer;
