import React from "react";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import SpaceBetween from "../styled/generic/SpaceBetween";
import FormBox from "../styled/generic/FormBox";
import { Box, Typography } from "@material-ui/core";
import { Grid, IconButton } from "@mui/material";
import WorkspaceApp from "../styled/generic/WorkspaceApp";
import { Sort } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { setAuthCookieData } from "../../helpers/cookie.helper";
import { useSelector } from "react-redux";
import config from '../../config/index';

const ProjectHomeWorkspace = ({ team, workspaceApps }) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const entireState = useSelector((state) => state);

  const getSubdomain = (code) => {
    switch (code) {
      case "ticketing":
      case "teams": return "teams";
      case "booking": return "bookings";
      case "planning": return "planning";
      case "bugs": return "bugs";
      case "accounts": return "accounts";
      case "documents": return "docs";
      case "timesheet": return "timesheets";
      case "procurement": return "operations";
      case "estimation": return "estimation";
      case "inventory": return "inventory";
      case "sitereport": return "sitereport";
      case "assets": return "assets";
      case "approval": return "approval";
      case "productservices": return "app";
      case "meeting": return "app";
      default: return "app";
    }
  };

  const handleAppClick = (code) => {
    if (config.mode === 'prod') {
      const token = localStorage.getItem("token");
      if (entireState && typeof entireState === 'object') {
        setAuthCookieData({
          user, token, entireState
        });
      } else {
        console.warn("Skipped state persistence due to invalid state");
      }
    }

    console.log(code)

    let projectData = team?.parent;
    let queryId = projectData._id;
    let queryType = "project";
    let profileId = projectData?.profile?._id;
    let walletId = team?.wallet;
    let teamId = team?._id;
    let org = null;
    let projectId = projectData?._id;

    let path = '';

    switch (code) {
      case "ticketing":
      case "teams":
        const url = location.pathname;
        const segments = url.split("/");
        const redirectedFromProject = segments[1] === "project" ? segments[2] : null;
        path = `/issues/profile/${profileId}${redirectedFromProject ? `?redirectedFromProject=${redirectedFromProject}` : ""}`;
        break;
      case "prefab":
        path = `/prefab/${profileId}`;
        break;
      case "booking":
        path = `/booking/${profileId}`;
        break;
      case "planning":
        path = `/planning/${profileId}`;
        break;
      case "bug":
        path = `/bugs/${profileId}`;
        break;
      case "accounts":
        path = `/finance/${walletId}/bank-accounts`;
        break;
      case "documents":
        path = `/docs/${profileId}`;
        break;
      case "timesheet":
        if (projectId && org?._id) {
          path = `/organization/workschedule/${org?.team}?projectId=${projectId}`;
        } else {
          path = `/organization/workschedule/${teamId}`;
        }
        break;
      case "productservices":
        path = `/offerings-categories/management/${profileId}`;
        break;
      case "procurement":
        path = `/procurements/management/${profileId}`;
        break;
      case "estimation":
        path = `/budget-analysis/budget-costs/${profileId}`;
        break;
      case "inventory":
        if (queryType === "project") {
          path = `/inventory?projectId=${queryId}`;
        } else if (queryType === "organization") {
          path = `/inventory?organizationId=${queryId}`;
        } else {
          path = `/inventory`;
        }
        break;
      case "sitereport":
        path = `/site-report/${profileId}`;
        break;
      case "assets":
        path = `/assets/home/${profileId}`;
        break;
      case "approval":
        path = `/approval/${profileId}`;
        break;
      case "investment":
        path = `/analysis/${teamId}`;
        break;
      case "meeting":
        path = `/meeting/${profileId}`;
        break;
      case "events":
      case "portfoliomanagement":
      case "propertymanagement":
        // These cases are not implemented in the original code
        return;
      default:
        alert("No action found for this type of app");
        return;
    }

    navigateTo(code, path);
  };

  const navigateTo = (code, path) => {
    if (config.mode === 'prod') {
      const subdomain = getSubdomain(code);
      if (subdomain === "app") {
        history.push(path);
      } else {
        const url = `https://${subdomain}.reallist.ai${path}`;
        window.open(url, "_self");
      }

    } else {
      history.push(path);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <StandardAppContainerRounded>
          <SpaceBetween
            rightStyleProps={{ alignSelf: "flex-start" }}
            left={
              <FormBox label="Apps">
                {/* <Typography variant="subtitle1">
                  Configure your apps here
                </Typography> */}
              </FormBox>
            }
            right={
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                {/* <Button
                  startIcon={<Add />}
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={() => setShowWorkspaceConfigDialog(true)}
                >
                  Apps
                </Button> */}
              </Box>
            }
          />
          <Box>
            <Grid container spacing={2}>
              {workspaceApps?.map((item) => (
                <Grid item xs={3}>
                  <WorkspaceApp
                    isSelectable={false}
                    onClick={() => handleAppClick(item.code)}
                    appData={item}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </StandardAppContainerRounded>
      </Grid>
      <Grid item xs={3}>
        <StandardAppContainerRounded>
          <SpaceBetween
            left={<Typography variant="h6">Activities</Typography>}
            right={
              <Box display="flex" justifyContent="flex-end">
                <IconButton>
                  <Sort />
                </IconButton>
              </Box>
            }
          />
        </StandardAppContainerRounded>
      </Grid>
    </Grid>
  );
};

export default ProjectHomeWorkspace;
