// Updated CreateTransactionTable component
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import TableContainer from "../../styled/generic/TableContainer";
import { Add, Delete } from "@mui/icons-material";

const CreateTransactionTable = ({
  paymentDetails,
  billItems,
  setBillItems,
  status = "Draft",
  type = "General Transaction",
  usedComponents = [],
}) => {
  const [availableChargeTypes, setAvailableChargeTypes] = useState([]);
  const [isAddingNewCell, setIsAddingNewCell] = useState(false);
  const [newCellData, setNewCellData] = useState({
    chargeType: "",
    chargeAmount: 0,
    currentDue: 0,
    penaltyDue: 0,
    rebateAmount: 0,
    totalAmountReceivable: 0,
    receivedAgainstCurrentDue: 0,
    amountReceived: 0,
  });

  const handleInputChange = (index, field, value) => {
    const updatedData = [...billItems];
    updatedData[index][field] = field === "chargeType" ? value : Number(value);
    if (type === "General Transaction") {
      if (field === "chargeType") {
        const selectedDetail = paymentDetails?.find(
          (detail) => detail.title === value
        );
        if (selectedDetail) {
          updatedData[index].chargeAmount = selectedDetail.chargeAmount;
          updatedData[index].currentDue = selectedDetail.currentDue;
          updatedData[index].componentRef = selectedDetail.componentRef;
          updatedData[index].detail = selectedDetail._id;
          updatedData[index].amountReceived = selectedDetail.amountReceived;
        }
        updatedData[index].receivedAgainstCurrentDue =
          updatedData[index].currentDue;
      }
      updatedData[index].totalAmountReceivable =
        (updatedData[index]?.currentDue || 0) +
        (updatedData[index]?.penaltyDue || 0) -
        (updatedData[index]?.rebateAmount || 0);
    } else if (type === "Debit Note") {
      updatedData[index].totalAmountReceivable =
        updatedData[index]?.penaltyDue || 0;
    } else {
      updatedData[index].totalAmountReceivable =
        -1 * (updatedData[index]?.rebateAmount || 0);
    }
    setBillItems(updatedData);
  };

  const saveNewCell = () => {
    const updatedData = [
      ...billItems,
      {
        ...newCellData,
        totalAmountReceivable:
          newCellData.currentDue +
          newCellData.penaltyDue -
          newCellData.rebateAmount,
      },
    ];
    setBillItems(updatedData);
    setIsAddingNewCell(false);
    setNewCellData({
      chargeType: "",
      chargeAmount: 0,
      currentDue: 0,
      penaltyDue: 0,
      rebateAmount: 0,
      totalAmountReceivable: 0,
      receivedAgainstCurrentDue: 0,
    });
  };

  const addNewCell = () => {
    const newCell = {
      chargeType: "",
      chargeAmount: 0,
      currentDue: 0,
      penaltyDue: 0,
      rebateAmount: 0,
      totalAmountReceivable: 0,
      receivedAgainstCurrentDue: 0,
      amountReceived: 0,
    };

    setBillItems([...billItems, newCell]);
  };

  useEffect(() => {
    const usedTitles = billItems?.map((data) => data?.chargeType);
    if (usedComponents.length > 0) {
      const availableTitles = paymentDetails
        .filter(
          (item) => usedComponents.includes(item?.componentRef?._id) === false
        )
        ?.filter(
          (item) =>
            !usedTitles.includes(item.title) &&
            item?.componentRef?.hideInTable === false
        )
        ?.map((item) => item.title);
      setAvailableChargeTypes(availableTitles);
    } else {
      const availableTitles = paymentDetails
        ?.filter(
          (item) =>
            !usedTitles.includes(item.title) &&
            item?.componentRef?.hideInTable === false
        )
        ?.map((item) => item.title);
      setAvailableChargeTypes(availableTitles);
    }
  }, [billItems]);

  const removeRow = (index) => {
    const updatedData = billItems?.filter((_, i) => i !== index);
    setBillItems(updatedData);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <TableContainer
        columns={
          type === "General Transaction"
            ? [
                "Charge Type",
                "Charge Amount",
                "Amount Received",
                "Current Due",
                "Penalty Amount",
                "Discount Amount",
                "Total Payable Amount",
                "Action",
              ]
            : type === "Debit Note"
            ? ["Charge Type", "Penalty Amount", "Action"]
            : ["Charge Type", "Discount Amount", "Action"]
        }
        containerHeight={""}
        data={billItems}
      >
        {billItems?.map((row, index) => (
          <tr key={index}>
            <td>
              <Select
                value={row?.chargeType}
                onChange={(e) =>
                  handleInputChange(index, "chargeType", e.target.value)
                }
                disabled={status !== "Draft"}
                fullWidth
              >
                {availableChargeTypes?.map((chargeType, idx) => (
                  <MenuItem key={chargeType} value={chargeType}>
                    {chargeType}
                  </MenuItem>
                ))}
                {paymentDetails?.map((item, idx) => (
                  <MenuItem
                    key={idx}
                    value={item?.title}
                    sx={{
                      display: "none",
                    }}
                  >
                    {item?.title}
                  </MenuItem>
                ))}
              </Select>
            </td>
            {type === "General Transaction" && (
              <>
                <td>
                  <TextField
                    disabled={true}
                    value={row.chargeAmount}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </td>
                <td>
                  <TextField
                    disabled={true}
                    value={row.amountReceived}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </td>
                <td>
                  <TextField
                    disabled={true}
                    value={row.currentDue}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </td>
              </>
            )}
            {(type === "General Transaction" || type === "Debit Note") && (
              <td>
                <TextField
                  disabled={status !== "Draft"}
                  value={row.penaltyDue}
                  type="number"
                  onChange={(e) =>
                    handleInputChange(index, "penaltyDue", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
            )}
            {(type === "General Transaction" || type === "Credit Note") && (
              <td>
                <TextField
                  disabled={status !== "Draft"}
                  value={row.rebateAmount}
                  type="number"
                  onChange={(e) =>
                    handleInputChange(index, "rebateAmount", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
            )}
            {type === "General Transaction" && (
              <td>
                <TextField
                  disabled={true}
                  value={row.totalAmountReceivable}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </td>
            )}

            <td>
              <IconButton
                onClick={() => removeRow(index)}
                disabled={status !== "Draft"}
              >
                <Tooltip title="Delete" placement="bottom" arrow>
                  <Delete />
                </Tooltip>
              </IconButton>
            </td>
          </tr>
        ))}
        {billItems.length > 0 && (
          <tr>
            <td>Total</td>
            {type === "General Transaction" && (
              <>
                <td>
                  <span style={{ marginLeft: "15px", marginRight: "8px" }}>
                    ₹
                  </span>
                  {billItems.reduce(
                    (acc, t) => acc + parseInt(t.chargeAmount || 0),
                    0
                  )}
                </td>
                <td>
                  <span style={{ marginLeft: "15px", marginRight: "8px" }}>
                    ₹
                  </span>
                  {billItems.reduce(
                    (acc, t) => acc + parseInt(t.amountReceived || 0),
                    0
                  )}
                </td>
                <td>
                  <span style={{ marginLeft: "15px", marginRight: "8px" }}>
                    ₹
                  </span>
                  {billItems.reduce(
                    (acc, t) => acc + parseInt(t.currentDue || 0),
                    0
                  )}
                </td>
              </>
            )}
            {(type === "General Transaction" || type === "Debit Note") && (
              <td>
                <span style={{ marginLeft: "15px", marginRight: "8px" }}>
                  ₹
                </span>
                {billItems.reduce(
                  (acc, t) => acc + parseInt(t.penaltyDue || 0),
                  0
                )}
              </td>
            )}
            {(type === "General Transaction" || type === "Credit Note") && (
              <td>
                <span style={{ marginLeft: "15px", marginRight: "8px" }}>
                  ₹
                </span>
                {billItems.reduce(
                  (acc, t) => acc + parseInt(t.rebateAmount || 0),
                  0
                )}
              </td>
            )}
            {type === "General Transaction" && (
              <td>
                <span style={{ marginLeft: "15px", marginRight: "8px" }}>
                  ₹
                </span>
                {billItems.reduce(
                  (acc, t) => acc + parseInt(t.totalAmountReceivable || 0),
                  0
                )}
              </td>
            )}
            <td></td>
          </tr>
        )}
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Button
          variant={"outlined"}
          color={"primary"}
          size="large"
          onClick={addNewCell}
          startIcon={<Add />}
          disabled={status !== "Draft"}
        >
          New Cell
        </Button>
      </Box>
    </Box>
  );
};
export default CreateTransactionTable;
