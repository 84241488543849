import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Add } from "@material-ui/icons";
import Dialog from "../../styled/generic/Dialog";
import FormBox from "../../styled/generic/FormBox";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import TextField from "../../styled/generic/TextField";
import Autocomplete from "../../styled/generic/Autocomplete";
import { LoadingButton } from "@mui/lab";
import { PROXIMITY_OPTIONS } from "../../../helpers/constants";
import axios from "axios";
import keyConfig from "../../../config/keys.config";
import { useDebounce } from "react-use";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import FormHeaderWithActions from "../../styled/generic/FormHeaderWithActions";
import GoogleMapComponent from "../../styled/CommonComponents/Google.Map";
import { updateProjectDataWithPopulated } from "../api.call";
import { useDispatch, useSelector } from "react-redux";
import teamUtils from "../team.utils";

export default function LocationInformation({ project }) {
  const { handleTeams } = teamUtils;
  const stateStore = useSelector((state) => state);
  const { teamDictionary } = useSelector((state) => state.team);
  const dispatch = useDispatch();

  const [mapPos, setMapPos] = useState([]);
  const [proximities, setProximities] = useState([]);
  const [places, setPlaces] = useState([]);
  const [placesLoading, setPlacesLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [placeText, setPlaceText] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [landMark, setLandMark] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const updateProject = async (obj) => {
    try {
      setIsLoading(true);
      const data = await updateProjectDataWithPopulated(obj);
      if (data) {
        let teamDataFromDic = teamDictionary[project?.team];
        let teamParent = teamDataFromDic?.parent;

        const team = {
          ...teamDataFromDic,
          parent: {
            ...teamParent,
            ...data,
          },
        };

        // Update Redux store using handleTeams
        handleTeams([team], stateStore, dispatch);

        // Update local state
        if (data.address?.lat && data.address?.lon) {
          setMapPos([
            {
              latitude: data.address.lat,
              longitude: data.address.lon,
              label:
                data.address?.address_line1 ||
                data.address?.address_line2 ||
                data.address?.name,
              marker_color: "FF5F1F",
              marker_text_color: "ffffff",
            },
          ]);
        }

        // Update other state values
        setCity(data.address?.city || "");
        setState(data.address?.state || "");
        setZip(data.address?.postcode || "");
        setLandMark(data.address?.landMark || "");
        setPlaceText(data.address?.name || "");

        // Update proximities
        const proximitiesArr = PROXIMITY_OPTIONS.filter((a) =>
          data.proximities?.find((b) => b === a.title)
        );
        setProximities(proximitiesArr);
      }
    } catch (err) {
      console.error("Error updating project:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const searchPlaces = async (text) => {
    try {
      setPlacesLoading(true);
      const options = {
        method: "GET",
        url: "https://address-completion.p.rapidapi.com/v1/geocode/autocomplete",
        params: {
          text: text,
          limit: "15",
          lang: "en",
          countrycodes: "in",
        },
        headers: {
          "X-RapidAPI-Key": keyConfig?.rapidApi,
          "X-RapidAPI-Host": "address-completion.p.rapidapi.com",
        },
      };

      let response = await axios.request(options);
      let places = response?.data?.features;
      let formatted_places = [];

      for (let i = 0; i < places?.length; i++) {
        let place = places[i];
        if (!place?.properties?.postcode) continue;

        if (place?.properties?.name) {
          formatted_places.push(place?.properties);
        } else {
          formatted_places.push({
            ...place?.properties,
            name: place?.properties?.address_line1,
          });
        }
      }

      setPlaces(formatted_places);
    } catch (err) {
      console.log("Error loading places");
    } finally {
      setPlacesLoading(false);
    }
  };

  useDebounce(
    () => {
      if (placeText?.length >= 2) {
        searchPlaces(placeText);
      }
    },
    1000,
    [placeText]
  );

  useEffect(() => {
    let location = project?.address;
    if (location?.lat && location?.lon) {
      setMapPos([
        {
          latitude: location.lat,
          longitude: location.lon,
          label:
            location?.address_line1 ||
            location?.address_line2 ||
            location?.name,
          marker_color: "FF5F1F",
          marker_text_color: "ffffff",
        },
      ]);
    }

    const proximitiesArr = PROXIMITY_OPTIONS.filter((a) =>
      project?.proximities?.find((b) => b === a.title)
    );

    setProximities(proximitiesArr);
    setCity(location?.city);
    setState(location?.state);
    setZip(location?.postcode);
    setLandMark(location?.landMark);
    setPlaceText(location?.name);
  }, [project]);

  useEffect(() => {
    if (selectedPlace?.state) setState(selectedPlace?.state);
    if (selectedPlace?.city) setCity(selectedPlace?.city);
    if (selectedPlace?.postcode) setZip(selectedPlace?.postcode);
    if (selectedPlace?.lat && selectedPlace?.lon) {
      setMapPos([
        {
          latitude: selectedPlace?.lat,
          longitude: selectedPlace?.lon,
          label:
            selectedPlace?.address_line1 ||
            selectedPlace?.address_line2 ||
            selectedPlace?.name,
          marker_color: "FF5F1F",
          marker_text_color: "ffffff",
        },
      ]);
    }
    setLandMark("");
  }, [selectedPlace]);

  return (
    <StandardAppContainerRounded>
      <FormHeaderWithActions
        label="Location Details"
        actions={
          <Box>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
              onClick={async () => {
                try {
                  await updateProject({
                    _id: project?._id,
                    proximities: proximities.map((item) => item?.title),
                    address: {
                      ...selectedPlace,
                      landMark: landMark,
                      city: city,
                    },
                  });
                } catch (err) {
                  console.error("Error saving location:", err);
                }
              }}
              sx={{
                textTransform: "none",
                minWidth: "100px",
              }}
            >
              {isLoading ? "Saving..." : "Save"}
            </LoadingButton>
          </Box>
        }
      />

      <Box sx={{ mt: 3, p: 2 }}>
        <SpaceBetween
          left={
            <FormBox label="Locality">
              <Autocomplete
                options={places}
                defaultValue={selectedPlace}
                onChange={(evt, newPlace) => setSelectedPlace(newPlace)}
                fullWidth
                getOptionLabel={(option) => option?.name || option}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    value={placeText}
                    onChange={(e) => setPlaceText(e.target.value)}
                    placeholder={placeText ? placeText : "Search location"}
                  />
                )}
                loading={placesLoading}
              />
            </FormBox>
          }
          right={
            <FormBox label="City">
              <TextField
                placeholder="Enter"
                fullWidth
                value={city}
                onChange={(evt) => setCity(evt.target.value)}
              />
            </FormBox>
          }
        />

        <SpaceBetween
          left={
            <FormBox label="State">
              <TextField
                placeholder="State Name"
                fullWidth
                value={state}
                onChange={(evt) => setState(evt.target.value)}
              />
            </FormBox>
          }
          right={
            <FormBox label="Zip Code">
              <TextField
                placeholder="Enter"
                fullWidth
                value={zip}
                onChange={(evt) => setZip(evt.target.value)}
              />
            </FormBox>
          }
        />

        <SpaceBetween
          left={
            <FormBox label="Land Mark">
              <TextField
                placeholder="Enter"
                fullWidth
                value={landMark}
                onChange={(evt) => setLandMark(evt.target.value)}
              />
            </FormBox>
          }
        />

        <FormBox label="Near By Proximities">
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {PROXIMITY_OPTIONS.map((item) => (
              <Grid item xs={3} key={item?.title}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    border: proximities.find((p) => p.title === item.title)
                      ? "1px solid #2D76E0AF"
                      : "1px solid rgba(0,0,0,0.15)",
                    p: 2,
                    borderRadius: "5px",
                    color: proximities.find((p) => p.title === item.title)
                      ? "#2D76E0"
                      : "#717171",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (proximities.find((p) => p.title === item.title)) {
                      setProximities(
                        proximities.filter((p) => p.title !== item.title)
                      );
                    } else {
                      setProximities([...proximities, item]);
                    }
                  }}
                >
                  {<item.icon style={{ fontSize: "32px" }} />}
                  <Typography variant="h6" style={{ marginLeft: "15px" }}>
                    {item?.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </FormBox>
      </Box>

      {mapPos.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <GoogleMapComponent
            marks={mapPos}
            MakerType={"maker"}
            height={400}
            redius={800}
          />
        </Box>
      )}
    </StandardAppContainerRounded>
  );
}
