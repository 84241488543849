import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import FormBox from "../../styled/generic/FormBox";
import TextField from "../../styled/generic/TextField";
import Api from "../../../helpers/Api";
import teamUtils from "../team.utils";
import OrgPicker from "../../styled/generic/OrgPicker";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    width: "100%",
    height: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingRight: "1rem",
    borderBottom: "1px solid #e4e7e7",
  },
  saveBtn: {
    width: "6rem",
    height: "2.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2d76e0",
    color: "white",
    borderRadius: "0.4rem",
    fontSize: "1.1rem",
  },
  inputCont: {
    paddingTop: "2rem",
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  },
  userSty: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    border: "1px solid #ececec",
    borderRadius: "5px",
    padding: "2px 0px",
    "& div": {
      marginLeft: "10px"
    },
    "& h3": {
      fontSize: "16px",
      fontWeight: "500"
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400"
    }
  }
});

const BasicInformation = ({ project }) => {
  const classes = useStyles();
  const { handleTeams } = teamUtils;
  const stateStore = useSelector((state) => state);
  const { teamDictionary } = useSelector((state) => state.team);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [displayName, setDisplayName] = useState(project?.displayName);
  const [description, setDescription] = useState(project?.description);
  const [email, setEmail] = useState(project?.email);
  const [phone, setPhone] = useState(project?.phone);
  const [tagline, setTagline] = useState(project?.tagline);
  const [username, setUsername] = useState(project?.username);
  const [gstNo, setGstNo] = useState(project?.gstNo);
  const [panNo, setPanNo] = useState(project?.panNo);
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState(project?.ownerProfile);
  const [ownerType, setOwnerType] = useState("");

  const saveEdit = async () => {
    try {
      setLoading(true);
      let obj = {
        displayName,
        description,
        email,
        phone,
        tagline,
        username,
        gstNo,
        panNo,
      };
      if(ownerType === "Organization" && owner?._id){
        obj.ownerProfile = owner?.profile?._id || owner?._id;
      }else if(ownerType === "Personal"){
        obj.ownerProfile = user?.profile;
      }

      const { data } = await Api.post("project/update/withpopulate", {
        _id: project?._id,
        ...obj,
      });
      if (data) {
        let teamDataFromDic = teamDictionary[project?.team];
        let teamParent = teamDataFromDic?.parent;

        const team = {
          ...teamDataFromDic,
          parent: {
            ...teamParent,
            ...data,
          },
        };
        handleTeams([team], stateStore, dispatch);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (project?.ownerProfile?.parentModelName === "Organization") {
      setOwnerType("Organization")
    } else if (project?.ownerProfile?.parentModelName === "User") {
      setOwnerType("Personal")
    }
  }, [project?.ownerProfile])

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span onClick={() => saveEdit()} className={classes.saveBtn}>
          {loading ? (
            <CircularProgress style={{ color: "white" }} size={24} />
          ) : (
            <span style={{ cursor: "pointer" }}>Save</span>
          )}
        </span>
      </div>
      <div className={classes.inputCont}>
        <FormBox label="Owner Type">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={ownerType}
            onChange={(evt, val) => setOwnerType(val)}
          >
            <FormControlLabel
              value="Personal"
              control={<Radio color="primary" />}
              label="Personal"
            />
            <FormControlLabel
              value="Organization"
              control={<Radio color="primary" />}
              label="Organization"
            />
          </RadioGroup>
        </FormBox>
        <FormBox label="Owner">
          {ownerType === "Organization" && (
            <OrgPicker
              selectedOrg={owner}
              setSelectedOrg={setOwner}
              defaultOrganizationProfileId={project?.ownerProfile?._id}
              fullWidth={true}
            />
          )}
          {ownerType === "Personal" && (
            <div className={classes.userSty} >
              <Avatar
                src={user?.displayPicture?.url}
              />
              <div>
                <h3>{user?.displayName}</h3>
                <p>{user?.username}</p>
              </div>
            </div>
          )}
        </FormBox>
        <FormBox label="Display Name">
          <TextField
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Enter Display Name"
            fullWidth={true}
          />
        </FormBox>
        <FormBox label="Email">
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="Description">
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description"
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="Phone">
          <TextField
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter Phone"
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="Tag line">
          <TextField
            value={tagline}
            onChange={(e) => setTagline(e.target.value)}
            placeholder="Enter tag line"
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="Username">
          <TextField
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter Username"
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="GST No">
          <TextField
            type="text"
            value={gstNo}
            onChange={(e) => setGstNo(e.target.value)}
            style={{ width: "100%" }}
          />
        </FormBox>
        <FormBox label="Pan No">
          <TextField
            type="text"
            value={panNo}
            onChange={(e) => setPanNo(e.target.value)}
            style={{ width: "100%" }}
          />
        </FormBox>
      </div>
    </div>
  );
};

export default BasicInformation;
