import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import FormBox from "../../styled/generic/FormBox";
import TextField from "../../styled/generic/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Api from "../../../helpers/Api";
import teamUtils from "../team.utils";

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    width: "100%",
    height: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingRight: "1rem",
    borderBottom: "1px solid #e4e7e7",
  },
  saveBtn: {
    width: "6rem",
    height: "2.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2d76e0",
    color: "white",
    borderRadius: "0.4rem",
    fontSize: "1.1rem",
  },
  label: {
    fontSize: "0.875rem",
    color: "#193B56",
    marginBottom: "4px",
  },
  inputCont: {
    paddingTop: "1rem",
    width: "95%",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "0.75rem",
  },
  pricingRow: {
    display: "flex",
    alignItems: "center",
    gap: "0.75rem",
    marginBottom: "0.75rem",
  },
  addButton: {
    marginTop: "1rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#2d76e0",
    gap: "0.5rem",
  },
});

const PricingInformation = ({ project }) => {
  const classes = useStyles();
  const { handleTeams } = teamUtils;
  const stateStore = useSelector((state) => state);
  const { teamDictionary } = useSelector((state) => state.team);
  const dispatch = useDispatch();

  const [pricePerSquareFoot, setPricePerSquareFoot] = useState(
    project?.pricePerSquareFoot || 0
  );
  const [price, setPrice] = useState(project?.price || "");
  const [pricingList, setPricingList] = useState(project?.pricing || []);
  const [loading, setLoading] = useState(false);

  const handleAddPricing = () => {
    setPricingList([...pricingList, { title: "", value: "" }]);
  };

  const handleRemovePricing = (index) => {
    const newList = pricingList.filter((_, i) => i !== index);
    setPricingList(newList);
  };

  const handlePricingChange = (index, field, value) => {
    const newList = [...pricingList];
    newList[index][field] = value;
    setPricingList(newList);
  };

  const saveEdit = async () => {
    try {
      setLoading(true);
      const pricingObj = {
        pricePerSquareFoot,
        price,
        pricing: pricingList,
      };

      const { data } = await Api.post("project/update/withpopulate", {
        _id: project?._id,
        ...pricingObj,
      });

      if (data) {
        let teamDataFromDic = teamDictionary[project?.team];
        let teamParent = teamDataFromDic?.parent;
        const team = {
          ...teamDataFromDic,
          parent: {
            ...teamParent,
            ...data,
          },
        };
        handleTeams([team], stateStore, dispatch);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span onClick={saveEdit} className={classes.saveBtn}>
          {loading ? (
            <CircularProgress style={{ color: "white" }} size={24} />
          ) : (
            <span style={{ cursor: "pointer" }}>Save</span>
          )}
        </span>
      </div>
      <div className={classes.inputCont}>
        <FormBox label="Base Price (₹)" labelStyle={classes.label}>
          <TextField
            type="number"
            value={price}
            onChange={(e) => setPrice(Math.max(0, Number(e.target.value)))}
            placeholder="Enter Base Price"
            size="small"
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{ min: 0 }}
          />
        </FormBox>
        <FormBox label="Price Per Sq.ft (₹)" labelStyle={classes.label}>
          <TextField
            type="number"
            value={pricePerSquareFoot}
            onChange={(e) =>
              setPricePerSquareFoot(Math.max(0, Number(e.target.value)))
            }
            placeholder="Enter Price Per Sq.ft"
            size="small"
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{ min: 0 }}
          />
        </FormBox>
      </div>

      <FormBox
        label="Additional Pricing Details"
        labelStyle={classes.label}
        style={{ width: "95%", marginTop: "1.5rem" }}
      >
        {pricingList.map((price, index) => (
          <div key={index} className={classes.pricingRow}>
            <TextField
              value={price.title}
              onChange={(e) =>
                handlePricingChange(index, "title", e.target.value)
              }
              placeholder="Enter Title"
              size="small"
              variant="outlined"
              style={{ width: "40%" }}
            />
            <TextField
              type="number"
              value={price.value}
              onChange={(e) =>
                handlePricingChange(
                  index,
                  "value",
                  Math.max(0, Number(e.target.value))
                )
              }
              placeholder="Enter Value"
              size="small"
              variant="outlined"
              style={{ width: "40%" }}
              inputProps={{ min: 0 }}
            />
            <IconButton
              onClick={() => handleRemovePricing(index)}
              size="small"
              style={{ padding: "4px" }}
            >
              <DeleteOutlineIcon style={{ fontSize: "18px" }} />
            </IconButton>
          </div>
        ))}
        <div className={classes.addButton} onClick={handleAddPricing}>
          <AddCircleOutlineIcon style={{ fontSize: "18px" }} />
          <span style={{ fontSize: "0.875rem" }}>Add Price Detail</span>
        </div>
      </FormBox>
    </div>
  );
};

export default PricingInformation;
