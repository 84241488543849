import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import FormBox from "../styled/generic/FormBox";
import { Add } from "@material-ui/icons";
import CreateProjectBlockDrawer from "../listing/common/drawers/CreateProjectBlockDrawer";
import ProjectUnitCreateDialog from "./Project.Unit.Create.Dialog";
import DataTableContainer from "../styled/generic/DataTableContainer";
import { AMENITY_OPTIONS, PROXIMITY_OPTIONS } from "../../helpers/constants";
import GoogleMapComponent from "../styled/CommonComponents/Google.Map";
import Dialog from "../styled/generic/Dialog";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import Autocomplete from "../styled/generic/Autocomplete";
import TextField from "../styled/generic/TextField";
import SpaceBetween from "../styled/generic/SpaceBetween";
import axios from "axios";
import keyConfig from "../../config/keys.config";
import { useDebounce } from "react-use";
import OrgPickerDropdown from "../styled/generic/OrgPickerDropdown";
import { updateProjectDataWithPopulated } from "./api.call";
import UnitAutocomplete from "../styled/CommonComponents/UnitAutocomplete";

const ProjectHomeAbout = ({
  team,
  projectBlocks,
  setProjectBlocks,
  reloadProject,
}) => {
  const [showCreateProjectBlockDrawer, setShowCreateProjectBlockDrawer] =
    useState(false);
  const [showCreateProjectUnitDrawer, setShowCreateProjectUnitDrawer] =
    useState(false);
  const [showConfigureAmenitiesDialog, setShowConfigureAmenitiesDialog] =
    useState(false);
  const [showConfigureLocationDialog, setShowConfigureLocationDialog] =
    useState(false);
  const [showConfigureAboutDialog, setShowConfigureAboutDialog] =
    useState(false);
  const [mapPos, setMapPos] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [actualAmenities, setActualAmenities] = useState([]);
  const [proximities, setProximities] = useState([]);
  const [actualProximities, setActualProximities] = useState([]);
  const [locality, setLocality] = useState("");
  const [places, setPlaces] = useState([]);
  const [placesLoading, setPlacesLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [placeText, setPlaceText] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [landMark, setLandMark] = useState("");
  const [area, setArea] = useState("");
  const [title, setTitle] = useState("");
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [description, setDescription] = useState("");
  const [areaUnit, setAreaUnit] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [tagline, setTagline] = useState("");
  const [username, setUsername] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [panNo, setPanNo] = useState("");

  const updateProject = async (obj) => {
    const { data } = await updateProjectDataWithPopulated(obj);
  };

  const searchPlaces = async (text) => {
    try {
      setPlacesLoading(true);
      const options = {
        method: "GET",
        url: "https://address-completion.p.rapidapi.com/v1/geocode/autocomplete",
        params: {
          text: text,
          limit: "15",
          lang: "en",
          countrycodes: "in",
        },
        headers: {
          "X-RapidAPI-Key": keyConfig?.rapidApi,
          "X-RapidAPI-Host": "address-completion.p.rapidapi.com",
        },
      };

      let response = await axios.request(options);

      let places = response?.data?.features;
      let formatted_places = [];

      for (let i = 0; i < places?.length; i++) {
        let place = places[i];

        if (!place?.properties?.postcode) {
          continue;
        }

        if (place?.properties?.name) {
          formatted_places.push(place?.properties);
        } else {
          formatted_places.push({
            ...place?.properties,
            name: place?.properties?.address_line1,
          });
        }
      }

      setPlaces(formatted_places);
    } catch (err) {
      console.log("Error loading places");
    } finally {
      setPlacesLoading(false);
    }
  };

  useDebounce(
    () => {
      if (placeText?.length >= 2) {
        searchPlaces(placeText);
      }
    },
    1000,
    [placeText]
  );

  useEffect(() => {
    let location = team?.parent?.address;
    if (location?.lat && location?.lon) {
      setMapPos([
        {
          latitude: location.lat,
          longitude: location.lon,
          label:
            location?.address_line1 ||
            location?.address_line2 ||
            location?.name,
          marker_color: "FF5F1F",
          marker_text_color: "ffffff",
        },
      ]);
    }
    const amenitiesArr = AMENITY_OPTIONS.filter((a) =>
      team?.parent?.amenities.find((b) => b === a.title)
    );
    setAmenities(amenitiesArr);
    setActualAmenities(amenitiesArr);

    const proximitiesArr = PROXIMITY_OPTIONS.filter((a) =>
      team?.parent?.proximities?.find((b) => b === a.title)
    );

    setProximities(proximitiesArr);
    setActualProximities(proximitiesArr);
    setCity(location?.city);
    setState(location?.state);
    setZip(location?.postcode);
    setLocality(location?.suburb);
    setLandMark(location?.landMark);
    setPlaceText(location?.name);
    setTitle(team?.parent?.displayName);
    setDescription(team?.parent?.description);
    setArea(team?.parent?.area);
    setAreaUnit(team?.parent?.areaUnit);
    setEmail(team?.parent?.email || "");
    setPhone(team?.parent?.phone || "");
    setTagline(team?.parent?.tagline || "");
    setUsername(team?.parent?.username || "");
    setGstNo(team?.parent?.gstNo || "");
    setPanNo(team?.parent?.panNo || "");
  }, [team]);

  useEffect(() => {
    if (selectedPlace?.state) {
      setState(selectedPlace?.state);
    }
    if (selectedPlace?.city) {
      setCity(selectedPlace?.city);
    }
    if (selectedPlace?.suburb) {
      setLocality(selectedPlace?.suburb);
    }
    if (selectedPlace?.postcode) {
      setZip(selectedPlace?.postcode);
    }
    if (selectedPlace?.lat && selectedPlace?.lon) {
      setMapPos([
        {
          latitude: selectedPlace?.lat,
          longitude: selectedPlace?.lon,
          label:
            selectedPlace?.address_line1 ||
            selectedPlace?.address_line2 ||
            selectedPlace?.name,
          marker_color: "FF5F1F",
          marker_text_color: "ffffff",
        },
      ]);
    }
    setLandMark("");
  }, [selectedPlace]);

  return (
    <>
      <StandardAppContainerRounded>
        <FormHeaderWithActions
          label="About"
          actions={
            <Box>
              <IconButton onClick={() => setShowConfigureAboutDialog(true)}>
                <Add />
              </IconButton>
            </Box>
          }
        />
        <Box display="flex" alignItems="center" sx={{ my: 2 }}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 600, marginRight: "15px" }}
          >
            Project Area: <span style={{ fontWeight: 500 }}>1200 sq ft</span>
          </Typography>

          <Typography
            variant="subtitle1"
            style={{ fontWeight: 600, marginRight: "15px" }}
          >
            Total Blocks: <span style={{ fontWeight: 500 }}>04</span>
          </Typography>

          <Typography
            variant="subtitle1"
            style={{ fontWeight: 600, marginRight: "15px" }}
          >
            Total Units: <span style={{ fontWeight: 500 }}>04</span>
          </Typography>
        </Box>
        <FormBox label="Description">
          <Typography variant="body1">
            {team?.parent?.description || "No Description"}
          </Typography>
        </FormBox>

        <FormBox label="Contact Information">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "text.secondary",
                    minWidth: "100px",
                  }}
                >
                  Email:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.primary",
                    wordBreak: "break-word",
                  }}
                >
                  {team?.parent?.email || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "text.secondary",
                    minWidth: "100px",
                  }}
                >
                  Phone:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.primary",
                  }}
                >
                  {team?.parent?.phone || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "text.secondary",
                    minWidth: "100px",
                  }}
                >
                  Username:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.primary",
                  }}
                >
                  {team?.parent?.username || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "text.secondary",
                    minWidth: "100px",
                  }}
                >
                  Tagline:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.primary",
                    fontStyle: "italic",
                  }}
                >
                  {team?.parent?.tagline || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "text.secondary",
                    minWidth: "100px",
                  }}
                >
                  GST Number:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.primary",
                    fontFamily: "monospace",
                  }}
                >
                  {team?.parent?.gstNo || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "text.secondary",
                    minWidth: "100px",
                  }}
                >
                  PAN Number:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.primary",
                    fontFamily: "monospace",
                  }}
                >
                  {team?.parent?.panNo || "-"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </FormBox>

        <FormBox label="Price Details">
          <Typography
            variant="body1"
            style={{
              marginBottom: "0.75rem",
              fontSize: "0.875rem",
            }}
          >
            Base Price: ₹{team?.parent?.price || 0}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginBottom: "0.75rem",
              fontSize: "0.875rem",
            }}
          >
            Price Per Sq.ft: ₹{team?.parent?.pricePerSquareFoot || 0}
          </Typography>

          {team?.parent?.pricing?.length > 0 && (
            <>
              <Typography
                variant="body2"
                style={{
                  marginTop: "0.75rem",
                  fontSize: "0.875rem",
                  fontWeight: 500,
                }}
              >
                Pricing Details:
              </Typography>
              <List dense>
                {team?.parent?.pricing.map((price, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`${price.title}: ₹${price.value}`}
                      primaryTypographyProps={{
                        style: {
                          fontSize: "0.875rem",
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </FormBox>
      </StandardAppContainerRounded>

      <StandardAppContainerRounded>
        <FormHeaderWithActions
          label="Amenities"
          actions={
            <Box>
              <IconButton onClick={() => setShowConfigureAmenitiesDialog(true)}>
                <Add />
              </IconButton>
            </Box>
          }
        />

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {actualAmenities.map((item) => (
            <Grid item xs={4} key={item?.title}>
              <Box display="flex" alignItems="center">
                {<item.icon style={{ fontSize: "25px" }} />}
                <Typography variant="body1" style={{ marginLeft: "15px" }}>
                  {item?.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </StandardAppContainerRounded>

      <StandardAppContainerRounded>
        <FormHeaderWithActions
          label="Location Details"
          actions={
            <Box>
              <IconButton onClick={() => setShowConfigureLocationDialog(true)}>
                <Add />
              </IconButton>
            </Box>
          }
        />
        <DataTableContainer>
          <tr>
            <td>
              <span style={{ fontWeight: 600 }}>Country:</span>{" "}
              {team?.parent?.address?.country || "-"}
            </td>
            <td>
              <span style={{ fontWeight: 600 }}>Street Address:</span>{" "}
              {team?.parent?.address?.address_line1 ||
                team?.parent?.address?.address_line2 ||
                "-"}
            </td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 600 }}>State:</span>{" "}
              {team?.parent?.address?.state || "-"}
            </td>
            <td>
              <span style={{ fontWeight: 600 }}>Land Mark:</span>{" "}
              {team?.parent?.address?.landMark || "-"}
            </td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 600 }}>City Name:</span>{" "}
              {team?.parent?.address?.city || "-"}
            </td>
            <td>
              <span style={{ fontWeight: 600 }}>Zipcode:</span>{" "}
              {team?.parent?.address?.postcode || "-"}
            </td>
          </tr>
        </DataTableContainer>
        <FormBox label="Near By Proximities" sx={{ mt: 3 }}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {actualProximities.map((item) => (
              <Grid item xs={4} key={item?.title}>
                <Box display="flex" alignItems="center">
                  {<item.icon style={{ fontSize: "25px" }} />}
                  <Typography variant="body1" style={{ marginLeft: "15px" }}>
                    {item?.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </FormBox>
        <Box sx={{ mt: 3 }}>
          <GoogleMapComponent
            marks={mapPos}
            MakerType={"maker"}
            height={400}
            redius={800}
          />
        </Box>
      </StandardAppContainerRounded>

      {/* <CreateProjectBlockDrawer
        open={showCreateProjectBlockDrawer}
        setOpen={setShowCreateProjectBlockDrawer}
        projectId={team?.parent?._id}
        onCreate={(data) => {
          setProjectBlocks([...projectBlocks, ...data]);
        }}
      /> */}

      <ProjectUnitCreateDialog
        open={showCreateProjectUnitDrawer}
        setOpen={setShowCreateProjectUnitDrawer}
        project={team?.parent}
        projectBlocks={projectBlocks}
      />

      <Dialog
        title="Manage Amenities"
        open={showConfigureAmenitiesDialog}
        setOpen={setShowConfigureAmenitiesDialog}
      >
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {AMENITY_OPTIONS.map((item) => (
            <Grid item xs={3} key={item?.title}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  border: amenities.find((a) => a.title === item.title)
                    ? "1px solid #2D76E0AF"
                    : "1px solid rgba(0,0,0,0.15)",
                  p: 2,
                  borderRadius: "5px",
                  color: amenities.find((a) => a.title === item.title)
                    ? "#2D76E0"
                    : "#717171",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (amenities.find((a) => a.title === item.title)) {
                    setAmenities(
                      amenities.filter((a) => a.title !== item.title)
                    );
                  } else {
                    setAmenities([...amenities, item]);
                  }
                }}
              >
                {<item.icon style={{ fontSize: "32px" }} />}
                <Typography variant="h6" style={{ marginLeft: "15px" }}>
                  {item?.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <DuoButtonGroup
          primaryButtonText="Apply"
          primaryButtonListener={async () => {
            await updateProject({
              _id: team?.parent?._id,
              amenities: amenities.map((item) => item?.title),
            });
            setActualAmenities(amenities);
            setShowConfigureAmenitiesDialog(false);
          }}
          secondaryButtonText="Close"
          secondaryButtonListener={() => setShowConfigureAmenitiesDialog(false)}
        />
      </Dialog>

      <Dialog
        open={showConfigureLocationDialog}
        setOpen={setShowConfigureLocationDialog}
        title="Location Details"
      >
        <Box>
          <SpaceBetween
            left={
              <FormBox label="Locality">
                <Autocomplete
                  options={places}
                  defaultValue={selectedPlace}
                  onChange={(evt, newPlace) => setSelectedPlace(newPlace)}
                  fullWidth
                  getOptionLabel={(option) => option?.name || option}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      value={placeText}
                      onChange={(e) => setPlaceText(e.target.value)}
                      placeholder={placeText ? placeText : "Search location"}
                    />
                  )}
                  loading={placesLoading}
                />
              </FormBox>
            }
            right={
              <FormBox label="City">
                <TextField
                  placeholder="Enter"
                  fullWidth
                  value={city}
                  onChange={(evt) => setCity(evt.target.value)}
                />
              </FormBox>
            }
          />

          <SpaceBetween
            left={
              <FormBox label="State">
                <TextField
                  placeholder="State Name"
                  fullWidth
                  value={state}
                  onChange={(evt) => setState(evt.target.value)}
                />
              </FormBox>
            }
            right={
              <FormBox label="Zip Code">
                <TextField
                  placeholder="Enter"
                  fullWidth
                  value={zip}
                  onChange={(evt) => setZip(evt.target.value)}
                />
              </FormBox>
            }
          />

          <SpaceBetween
            left={
              <FormBox label="Land Mark">
                <TextField
                  placeholder="Enter"
                  fullWidth
                  value={landMark}
                  onChange={(evt) => setLandMark(evt.target.value)}
                />
              </FormBox>
            }
          />

          <Box>
            <FormBox label="Near By Proximities">
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {PROXIMITY_OPTIONS.map((item) => (
                  <Grid item xs={3} key={item?.title}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        border: proximities.find((p) => p.title === item.title)
                          ? "1px solid #2D76E0AF"
                          : "1px solid rgba(0,0,0,0.15)",
                        p: 2,
                        borderRadius: "5px",
                        color: proximities.find((p) => p.title === item.title)
                          ? "#2D76E0"
                          : "#717171",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (proximities.find((p) => p.title === item.title)) {
                          setProximities(
                            proximities.filter((p) => p.title !== item.title)
                          );
                        } else {
                          setProximities([...proximities, item]);
                        }
                      }}
                    >
                      {<item.icon style={{ fontSize: "32px" }} />}
                      <Typography variant="h6" style={{ marginLeft: "15px" }}>
                        {item?.title}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <DuoButtonGroup
                primaryButtonText="Apply"
                primaryButtonListener={async () => {
                  await updateProject({
                    _id: team?.parent?._id,
                    proximities: proximities.map((item) => item?.title),
                    address: {
                      ...selectedPlace,
                      landMark: landMark,
                      city: city,
                    },
                  });

                  setActualProximities(proximities);
                  setShowConfigureLocationDialog(false);
                }}
                secondaryButtonText="Close"
                secondaryButtonListener={() =>
                  setShowConfigureLocationDialog(false)
                }
              />
            </FormBox>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        title="Project Details"
        open={showConfigureAboutDialog}
        setOpen={setShowConfigureAboutDialog}
      >
        <SpaceBetween
          left={
            <FormBox label="Enter Area">
              <TextField
                fullWidth
                value={area}
                onChange={(evt) => setArea(evt.target.value)}
                placeholder="Enter"
                type="number"
              />
            </FormBox>
          }
          right={
            <FormBox label="Unit Type">
              <UnitAutocomplete
                fullWidth={true}
                value={areaUnit}
                size="large"
                onChange={(event, value) => setAreaUnit(value)}
              />
            </FormBox>
          }
        />

        <SpaceBetween
          left={
            <FormBox label="Project Title">
              <TextField
                fullWidth
                value={title}
                onChange={(evt) => setTitle(evt.target.value)}
                placeholder="Enter"
              />
            </FormBox>
          }
          right={
            <FormBox label="Select Organization">
              <OrgPickerDropdown
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg}
                defaultOrganizationId={team?.parent?.ownerProfile?.parent?._id}
                hideLabel={true}
              />
            </FormBox>
          }
        />
        <FormBox label="Description">
          <TextField
            fullWidth
            multiline
            rows={5}
            value={description}
            onChange={(evt) => setDescription(evt.target.value)}
            placeholder="Enter"
          />
        </FormBox>
        <SpaceBetween
          left={
            <FormBox label="Email">
              <TextField
                fullWidth
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
                placeholder="Enter email"
                type="email"
              />
            </FormBox>
          }
          right={
            <FormBox label="Phone">
              <TextField
                fullWidth
                value={phone}
                onChange={(evt) => setPhone(evt.target.value)}
                placeholder="Enter phone number"
              />
            </FormBox>
          }
        />

        <SpaceBetween
          left={
            <FormBox label="Username">
              <TextField
                fullWidth
                value={username}
                onChange={(evt) => setUsername(evt.target.value)}
                placeholder="Enter username"
              />
            </FormBox>
          }
          right={
            <FormBox label="Tagline">
              <TextField
                fullWidth
                value={tagline}
                onChange={(evt) => setTagline(evt.target.value)}
                placeholder="Enter tagline"
              />
            </FormBox>
          }
        />

        <SpaceBetween
          left={
            <FormBox label="GST Number">
              <TextField
                fullWidth
                value={gstNo}
                onChange={(evt) => setGstNo(evt.target.value)}
                placeholder="Enter GST number"
              />
            </FormBox>
          }
          right={
            <FormBox label="PAN Number">
              <TextField
                fullWidth
                value={panNo}
                onChange={(evt) => setPanNo(evt.target.value)}
                placeholder="Enter PAN number"
              />
            </FormBox>
          }
        />

        <DuoButtonGroup
          primaryButtonText="Apply"
          primaryButtonListener={async () => {
            updateProject({
              _id: team?.parent?._id,
              displayName: title,
              description: description,
              ownerProfile: selectedOrg?._id,
              area: area,
              areaUnit: areaUnit,
              email: email,
              phone: phone,
              tagline: tagline,
              username: username,
              gstNo: gstNo,
              panNo: panNo,
            });

            setShowConfigureAboutDialog(false);
          }}
          secondaryButtonText="Close"
          secondaryButtonListener={() => setShowConfigureAboutDialog(false)}
        />
      </Dialog>
    </>
  );
};

export default ProjectHomeAbout;
