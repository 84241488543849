import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Drawer } from "@material-ui/core";
import { BsWindowDock } from "react-icons/bs";
import { PiNetwork } from "react-icons/pi";
import { BsFillPersonBadgeFill } from "react-icons/bs";
import { PiAddressBook } from "react-icons/pi";
import { BsBank } from "react-icons/bs";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BasicInformation from "./BasicInformation";
import LocationInformation from "./LocationInformation";
import { FaRupeeSign } from "react-icons/fa";
import PricingInformation from "./PricingInformation";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90vw",
    display: "flex",
    height: "100vh",
    flexDirection: "column",
  },
  mainEditCont: {
    width: "90vw",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    borderTop: "1px solid #e4e7e7",
  },
  leftNavBar: {
    width: "20%",
    height: "100%",
    borderRight: "1px solid #e4e7e7",
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  rightNavBar: {
    width: "80%",
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 200px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  header: {
    height: "4rem",
    borderTop: "1px solid #e4e7e7",
    borderBottom: "1px solid #e4e7e7",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0rem 1rem",
  },
  optionBtn: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "0px",
    fontSize: "16px",
    opacity: "0.6",
    justifyContent: "flex-start",
    paddingLeft: "20px",
    height: "52px",
    borderTop: "1px solid #e4e7e7",
    "& p": {
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      paddingLeft: "4px",
      "& p": {
        textTransform: "capitalize",
        textAlign: "left",
        width: "160px",
      },
    },
  },
}));

export default function ProjectSettingsDrawer({ open, project, setOpen }) {
  const classes = useStyles();
  const [settingsView, setSettingsView] = useState("basicInfo");

  return (
    <Drawer anchor="right" open={open}>
      <div className={classes.root}>
        <div className={classes.header}>
          <div style={{ display: "flex", alignItems: "center", gap: "0.8rem" }}>
            <span>
              <ArrowBackIosIcon style={{ color: "#2d76e0" }} />
            </span>
            <span style={{ fontSize: "1.4rem", fontWeight: "500" }}>
              {project?.displayName}
            </span>
          </div>
          <span onClick={() => setOpen(false)}>
            <CloseIcon style={{ color: "#2d76e0", cursor: "pointer" }} />
          </span>
        </div>
        <div className={classes.mainEditCont}>
          <div className={classes.leftNavBar}>
            <Button
              className={classes.optionBtn}
              onClick={() => setSettingsView("basicInfo")}
              style={
                settingsView === "basicInfo"
                  ? { opacity: "1", borderTop: "none" }
                  : { borderTop: "none" }
              }
            >
              <BsWindowDock style={{ fontSize: "19px", marginRight: "15px" }} />
              <p>Basic Information</p>
            </Button>
            <Button
              className={classes.optionBtn}
              onClick={() => setSettingsView("location")}
              style={settingsView === "location" ? { opacity: "1" } : {}}
            >
              <PiAddressBook
                style={{ fontSize: "21px", marginRight: "15px" }}
              />
              <p>Location</p>
            </Button>
            <Button
              className={classes.optionBtn}
              onClick={() => setSettingsView("teams")}
              style={settingsView === "teams" ? { opacity: "1" } : {}}
            >
              <PiNetwork style={{ fontSize: "21px", marginRight: "15px" }} />
              <p>Teams</p>
            </Button>
            <Button
              className={classes.optionBtn}
              onClick={() => setSettingsView("roles")}
              style={settingsView === "roles" ? { opacity: "1" } : {}}
            >
              <BsFillPersonBadgeFill
                style={{ fontSize: "21px", marginRight: "15px" }}
              />
              <p>Roles</p>
            </Button>
            <Button
              className={classes.optionBtn}
              onClick={() => setSettingsView("billing")}
              style={settingsView === "billing" ? { opacity: "1" } : {}}
            >
              <BsBank style={{ fontSize: "21px", marginRight: "15px" }} />
              <p>Billing</p>
            </Button>
            <Button
              className={classes.optionBtn}
              onClick={() => setSettingsView("pricing")}
              style={settingsView === "pricing" ? { opacity: "1" } : {}}
            >
              <FaRupeeSign style={{ fontSize: "19px", marginRight: "15px" }} />
              <p>Pricing</p>
            </Button>
          </div>
          <div className={classes.rightNavBar}>
            {settingsView === "basicInfo" && (
              <BasicInformation project={project} />
            )}
            {settingsView === "location" && (
              <LocationInformation project={project} />
            )}
            {settingsView === "teams" && <div>Teams Content</div>}
            {settingsView === "roles" && <div>Roles Content</div>}
            {settingsView === "billing" && <div>Billing Content</div>}
            {settingsView === "pricing" && (
              <PricingInformation project={project} />
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
}
